import axios from 'axios'
import {router} from '../router/index'
import qs from 'qs'
import { Toast } from 'vant'
import { Loading, MessageBox } from 'element-ui';
import store from '../store'


// 请求
const api = axios.create({
    baseURL: "/",
    timeout: 100000
})
// api.defaults.baseURL = (window.location.href.indexOf('192.168') != -1 || window.location.href.indexOf('.test.') != -1) ? 'http://service.test.wormhoo.com' : 'https://service.wormhoo.com';
// 请求拦截
api.interceptors.request.use((config) => {
    // 视频上传接口超时时间另外设置
    if(config.url.indexOf('/api/platform/test/video')>-1 || config.url.indexOf('/api/platform/fen/video')>-1){
        config.timeout = 10000 * 300
    }
    // console.log(config)
    let token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).access_token : ''
    if(!config.host) config.headers.Authorization = 'Bearer ' + token
    let qsList = ['/api/auth/sms','/api/order/make','/api/flat/test/answer',
    '/api/goods/test/answer','/api/users/flat/record','/api/flat/record/list',
    '/api/flatform/record/list','/api/test/put']
    // config.headers.request_from = 'pc';
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

    if (config.method == 'get' && !config.api2) {
        let params = [];
        for (const key in config.data) {
            if (Object.hasOwnProperty.call(config.data, key)) {
                const element = config.data[key];
                params.push(key + '=' + element)
            }
        }
        params = '?' + params.join('&');
        config.url += params;
    }else if(qsList.includes(config.url) && !config.api2){
        config.data = qs.stringify(config.data)
    }

    if(config.host) {
        config.url = config.host + config.url
    }
    
    
    // config.withCredentials = false;
    return config
}, err => {
    console.log(err);
})

//在 main.js 设置全局的请求次数，请求的间隙
api.defaults.retry = 4;
api.defaults.retryDelay = 1000;

api.interceptors.response.use(res => {
    // 对响应码的处理
    if (res.status == 200) {
        if(res.data.code == 1002) {
            localStorage.clear();
            store.commit('SET_LOGINSTATUS', false)
            store.commit('SET_USER', null)
            store.commit('SET_STUDY', null)
            MessageBox.confirm(res.data.msg, '提示', {
                confirmButtonText: '立即登录',
                cancelButtonText: '回到首页',
                type: 'warning'
              }).then(() => {
                store.commit('changeLoginShow', true)
              }).catch(() => {
                router.push(`/${router.app.$route.params.plat_type}/index`);         
              });
            
        } else {
            return res.data
        }
    }
    
}, error => {
    console.log(error)
    if(error.code!=='ECONNABORTED' && error.message.indexOf('timeout') == -1 && error.code != 'ERR_NETWORK' && error.code != 'ERR_BAD_RESPONSE') {
        Toast(error.response.status!==404 ? '系统错误，请稍后再试' : '接口不存在404')
        return
    }
    if(error.code == 'ERR_BAD_RESPONSE') {
        // Toast('网络连接超时')
        // return
       return {
           code: 'network',
           msg: '网络连接超时'
       }
   }
    let config = error.config;
    // If config does not exist or the retry option is not set, reject
    if(!config || !config.retry) return err;
    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0;
    // Check if we've maxed out the total number of retries
    if(config.__retryCount >= config.retry) {
        // Reject with the error
        if(error.code=='ECONNABORTED' && error.message.indexOf('timeout') != -1) {
             Toast('网络连接超时')
             return
            // return {
            //     code: 'timeout',
            //     msg: '网络连接超时'
            // }
        }
        if(error.code == 'ERR_NETWORK' || error.code == 'ERR_BAD_RESPONSE') {
             Toast('网络连接超时')
             return
            // return {
            //     code: 'network',
            //     msg: '网络连接超时'
            // }
        }
        return error;
    }
    // Increase the retry count
    config.__retryCount += 1;
    
    // Create new promise to handle exponential backoff
    var backoff = new Promise(function(resolve) {
        setTimeout(function() {
            resolve();
        }, config.retryDelay || 1);
    });
    
    // Return the promise in which recalls axios to retry the request
    return backoff.then(function() {
        // console.log('4444')
        // console.log(config)
        config.api2 = true
        return api(config);
    });

})

// 返出
export default api
