// import jWeixin from './jssdk_1.6.0.js';

import { router } from '../router'
import store from '../store'

const until = {
  maxItem(arr,name){
    let i = 0
    arr.forEach((item,index)=>{
     if(item[name] > arr[i][name]){
       i = index
     }
    })
    return arr[i]
 },
  loginRequire(){
    return true
    if (!store.state.isLogin) {
      store.commit('changeLoginShow', true)
    }else{
      return true
    }
  },
  toPageOpen(url){
    if(url.indexOf('http')==-1){
      window.open( '#/' + router.app._route.params.plat_type + url)
    }else{
      window.open(url)
    }
  },
  
  isWeiXin () {
    var ua = window.navigator.userAgent.toLowerCase();
    if ( (ua.match(/MicroMessenger/i) == 'micromessenger' ) && (ua.match(/wxwork/i) != 'wxwork')) {
      // console.log(WeixinJSBridge)
      // jWeixin.miniProgram.getEnv(function(res) {

      //   if (res.miniprogram) {
      //     return("mini-wx");
      //   } else {
      //     return true;
      //   }
      // });
      if (window.__wxjs_environment === 'miniprogram') {
        return 'wxapp';
      } else {
        return true;
      }
      // return true; //是微信浏览器(企业微信排除在外)
    } else {
      return false; //其他设备
    }

  },
    // 验证身份证号码
  checkCard(str) {
    const arg1 = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    if (!str.trim()) {
      return '请输入身份证号码'
    }
    if (!arg1.test(str)) {
      return '请输入正确的身份证号码'
    }
    return 'ok'
  },

  // 验证手机号
  checkPhone(str) {
    const regPhone = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|17[0-9]{1}|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/
    if (!str) {
      return '请输入手机号'
    }

    if (!regPhone.test(str)) {
      return '请填写正确的手机号'
    }
    return 'ok'
  },
  timeCount(time){
    let timeAll = time
    let h = parseInt(timeAll/ (60 * 60))
    timeAll = timeAll - h * 60 * 60
    let m = parseInt(timeAll/60)
    timeAll = timeAll - m * 60
    let s = timeAll
    return{
      h:h.toString().padStart(2,'0'),
      m:m.toString().padStart(2,'0'),
      s:s.toString().padStart(2,'0')
    }
  }
    
}

export default until
