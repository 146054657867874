<template>
  <div :style="{'margin-top': $route.name=='index' && $route.params.plat_type == 'tuoyu' ? '0' : '40px'}" v-show="VIPShow"> 
    <div class="vip" :class="{ vip2: top > height }">
      <div class="vipMain">
        <p v-if="vipInfo.is_buy">尊敬的会员用户，VIP会员有效期到{{ vipInfo.end_time }}</p>
        <p v-else>开通{{ platName }}VIP会员，畅享精品课程</p>
        <div class="price" v-if="!vipInfo.is_buy">
          <p class="pricePay">现价</p>
          <p class="pricePay">￥</p>
          <p class="pricePay">{{ vipInfo.price_promote }}</p>
          <span>原价￥{{ vipInfo.price }}</span>
        </div>
        <img
          :src="!vipInfo.is_buy ? payBtn : payBtn"
          @click="toPage()"
        />
      </div>
    </div>
    <div class="footer" id="footer" v-if="$route.params.plat_type !== 'guangxi' &&  $route.params.plat_type !== 'zhongtuo'"> 
      <div class="footer-content" >
        <div class="footer-left" v-if=" $route.params.plat_type !== 'zhongtuo'">
          <img
            :src="logo"
          />
          <p>
            主办单位：中国人口学会托育服务分会<br/>
            邮箱: tuoyufuwu@sina.com<br/>
            邮编：100081<br/>
            地址：北京市海淀区大慧寺路12号
          </p>
        </div>
        
        <div class="ma">
          <el-popover
            placement="top"
            width="200"
            trigger="hover">
            <div class="popoverMa">
               <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuoWX.jpg"/>
            </div>
            <el-button slot="reference" class="popoverButton">
              <img
                src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuo_wx.png"
              />
              微信公众号
            </el-button>
          </el-popover>
          <!-- <el-popover
            placement="top"
            width="200"
            trigger="hover"> -->
            <!-- <div class="popoverMa">
              <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/ma1.jpg"/>
            </div> -->
            <el-button slot="reference" class="popoverButton">
              <img
                src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuo_wb.png"
              />
              微博
            </el-button>
          <!-- </el-popover> -->
          <!-- <el-popover
            placement="top"
            width="200"
            trigger="hover"> -->
            <!-- <div class="popoverMa">
              <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/ma1.jpg"/>
            </div> -->
            <el-button slot="reference" class="popoverButton">
              <img
                src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuo_dy.png"
              />
              抖音
            </el-button>
          <!-- </el-popover> -->
          <!-- <el-popover
            placement="top"
            width="200"
            trigger="hover"> -->
            <!-- <div class="popoverMa">
              <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/ma1.jpg"/>
            </div> -->
            <el-button slot="reference" class="popoverButton">
              <img
                src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuo_ks.png"
              />
              快手
            </el-button>
          <!-- </el-popover> -->
          <!-- <el-popover
            placement="top"
            width="200"
            trigger="hover"> -->
            <!-- <div class="popoverMa">
              <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/ma1.jpg"/>
            </div> -->
            <el-button slot="reference" class="popoverButton">
              <img
                src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuo_xhs.png"
              />
              小红书
            </el-button>
          <!-- </el-popover> -->
        </div>
      </div>
      <p v-if=" $route.params.plat_type !== 'zhongtuo'">版权所有 © 中国人口学会托育服务分会 京ICP备14036938号 京学集团提供技术支持</p>
    </div>
    <div class="wjwFooter" v-if="$route.params.plat_type == 'guangxi'">
      京学爱尔福托育总部  技术支持
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      payBtn:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/vip_btn.jpg',
      payBtn2:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/vip_btn2.png',
      height: 0,
      top: 0,
    };
  },
  computed: {
    vipInfo() {
      return this.$store.state.vipInfo || {};
    },
    platName() {
      return this.$store.state.plat.name;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    VIPShow(){
      return this.$store.state.VIPShow
    },
    logo() {
      return this.$store.state.plat.logo;
      // return "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyulogo.png";
    },
  },
  mounted() {
    this.height = document.documentElement.clientHeight;
    if(document.getElementById("footer")){
      this.handleScrollToc();
       window.addEventListener("scroll", this.handleScrollToc, true);
    }
    // if(this.plat){
    //   this.getVip();
    // }
    this.baidu()
  },
  methods: {
    baidu(){
      var hm = document.createElement("script");
      if(this.plat==205){
        hm.src = "https://hm.baidu.com/hm.js?19ae1f69530bdb75fc4f25f9c303a98b";
      }else{
        hm.src = "https://hm.baidu.com/hm.js?e1277ef9c8325c6e47a49f9f399f350b";
      }
      let s = document.getElementsByTagName("script")[0]; 
          s.parentNode.insertBefore(hm, s);
    },
    async getVip() {
      let res = await this.$api({
        method: "get",
        url: "/api/platform/university/index",
        data: {
          flat_id: this.plat,
          is_interior: 2,
          terminal: 1, //1 pc 2 小程序
        },
      });
      let vipInfo = res.data.plat_vip;
      this.$store.commit("SET_VIP", vipInfo);
    },
    toPage() {
      if (!localStorage.getItem("user")) {
        this.$store.commit("changeLoginShow", true);
      } else {
        this.$router.push({
          path: "./detailVip",
          query: {
            id: this.vipInfo.goods_id,
          },
        });
      }
    },
    handleScrollToc() {
      if(!document.getElementById("footer")){
        window.removeEventListener("scroll",this.handleScrollToc)
        return
      }
      let info = document.getElementById("footer").getBoundingClientRect();
      this.top = info.top;
      // console.log('VIPShow',this.VIPShow);
      // console.log('height',this.height);
      // console.log('top',this.top);
    },
  },
};
</script>

<style scoped lang="scss">
.wjwFooter{
  width: 100%;
    background: #356cd4;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 12px;
    margin: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    color: #fff;
}
.footer {
  color: #333333;
  display: block;
  background: #f1f1f1;
  height: 214px;
  .footer-content {
    height: 182px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    .footer-left{
      > img {
        width: 157px;
        margin-bottom: 10px;
      }
      > p {
        margin: 0;
        text-align: left;
        line-height: 20px;
        font-size: 12px;
        span {
          color: #666666;
        }
      }
    }
    
    
    .ma {
      font-size: 12px;
      
      .popoverButton {
        margin-right: 30px;
        display: inline-block;
        text-align: center;
        padding: 0;
        background: transparent;
        border: 0;
        img {
          display: block;
          margin: 0 auto 10px;
          width: 37px;
        }
      }
    }
  }
  > p {
    width: 100%;
    background: #e2e2e2;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 12px;
    margin: 0;
  }
}
.vip {
  display: none;
  background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/vip_bg.jpg")
    center no-repeat;
  width: 100%;
  height: 64px;

  .vipMain {
    height: 100%;
    width: 1200px;
    display: flex;
    align-items: center;
    text-align: left;
    margin: 0 auto;
    > p {
      flex: 1;
      color: #222222;
      font-size: 24px;
      text-align: left;
    }
    .price {
      display: flex;
      align-items: center;
      padding-right: 56px;
      p {
        font-weight: bold;

        &:first-of-type {
          font-size: 23px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #201a10;
          // line-height: 20px;
          // text-shadow: 2px 0px 0px #921C11;

          background: linear-gradient(-42deg, #201a10 0%, #615444 35%, #262316 84%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          filter: drop-shadow(2px 0px 0px #fff8f1);
        }
        &:nth-of-type(2) {
          font-size: 14px;
          font-family: Source Han Sans CN;
          color: #efcf9c;
          line-height: 28px;
          // filter: 0drop-shadow(2px 0px 0px #921C11);
          align-self: start;
          padding-left: 8px;
          background: linear-gradient(-42deg, #201a10 0%, #615444 35%, #262316 84%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        &:nth-of-type(3) {
          font-size: 40px;
          color: #fcebde;

          background: linear-gradient(-42deg, #e94a30 0%, #ff5943 55%, #e15038 100%);
          filter: drop-shadow(2px 0px 0px #fff8f1);

          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      span {
        font-size: 22px;
        font-weight: 400;
        text-decoration: line-through;
        color: #6d6652;
        display: block;
        padding-left: 25px;
        display: none;
      }
    }
    img {
      cursor: pointer;
    }
  }
}
.vip2 {
  bottom: 0;
  left: 0;
  z-index: 100;
  position: fixed;
}
</style>